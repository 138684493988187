/* Print claims form
*****************************/
@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    width: 100%;
    margin: 1in 1.2in .5in 1.2in;
    float: none;
    height: 100%;
    padding: 0;
  }
  a[href]:after {
    content: none !important;
  }
  .col-xs-12.order,
  .added-product {
    word-wrap: break-word;
    page-break-before: auto;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  body,
  html {
    width: auto;
    height: auto;
    overflow: visible;
    background: #fff !important;
  }
  body {
    font-size: 12pt;
    line-height: 18pt;
  }
  body a:link {
    font-weight: bold;
    color: #06c !important;
  }
  #header,
  #site-search,
  #site-usp,
  #breadcrumbs,
  #sidebar,
  #finished-claim-form,
  #footer,
  #cookieInfo,
  .basket-freight-plans,
  .checkout-continue,
  #header-language-select-container,
  .modal,
  #checkout-steps,
  .cart-orderline__input-comments {
    display: none;
  }
  .cart-freight-plans #print-logo,
  .modal-open #print-logo {
    display: none !important;
  }
  .cart-freight-plans #modal-dialog,
  .modal-open #modal-dialog,
  .cart-freight-plans .customer-center-content,
  .modal-open .customer-center-content,
  .cart-freight-plans .container,
  .modal-open .container {
    width: 100%;
  }
  .cart-freight-plans .col-xs-12.col-sm-6.form-group,
  .modal-open .col-xs-12.col-sm-6.form-group {
    width: 50%;
  }
  .cart-freight-plans .modal-section-header,
  .modal-open .modal-section-header {
    width: 100% !important;
  }
  .cart-freight-plans #claim-modal,
  .modal-open #claim-modal {
    position: static;
    overflow: visible;
  }
  .cart-freight-plans textarea,
  .modal-open textarea {
    resize: none;
    height: 170px;
    width: 100%;
  }
  .print-fullwidth {
    width: 100% !important;
  }
  #return-id-wrapper {
    border: 3px solid red !important;
    display: block;
  }
  #print-logo {
    display: block !important;
    text-align: center;
    margin-bottom: 5pt;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .checkout-header h1 {
    font-size: 14pt;
  }
  .invoice-address table,
  .delivery-address table {
    margin-bottom: 10px;
  }
  .invoice-address table p,
  .delivery-address table p {
    margin: 0;
  }
  .added-to-basket .added-product {
    margin-bottom: 25px;
  }
  .added-to-basket .added-product .product-details .product-stock span[class*="stock-status"].stock-status-red:before,
  .product-stock img {
    display: none;
  }
  .added-to-basket .added-product {
    padding: 10px;
  }
  .added-to-basket .added-product .product-price-details {
    clear: none;
    padding: 0;
    background: none;
    border: 0;
  }
  .added-to-basket .added-product .product-price-details input {
    text-align: right;
  }
  .added-to-basket .added-product figure {
    margin: 0 10px 10px 0;
  }
  .product-price-container,
  .added-to-basket .added-product .product-name span {
    font-size: 10pt;
  }
  .added-to-basket .added-product .product-name .product-id,
  .product-price-container .price-info .sales-price strong {
    font-size: 12pt;
  }
  .product-add-reference input {
    box-shadow: none;
    border: 0;
    width: 100%;
  }
  .ng-hide {
    display: none !important;
  }
  .landing-page-print #hero {
    min-height: 465px;
    padding-top: 89px;
    padding-bottom: 109px;
  }
  .landing-page-print #hero:before {
    display: none;
  }
  .landing-page-print #hero .hero-text {
    float: left;
    width: 65%;
    padding-top: 30px;
    padding-bottom: 0;
    text-align: left;
    width: 100%;
  }
  .landing-page-print #hero .hero-text:before,
  .landing-page-print #hero .hero-text:after {
    content: " ";
    display: table;
  }
  .landing-page-print #hero .hero-text:after {
    clear: both;
  }
  .landing-page-print #hero .hero-text ul li {
    width: auto;
    text-align: left;
    margin-right: 20px;
  }
  .landing-page-print #hero .hero-text ul li svg {
    float: left;
    width: 27px;
    height: 27px;
    margin: 0 10px 0 0;
    fill: #000;
  }
  .landing-page-print #hero .hero-text h1 {
    font-size: 50px;
    line-height: 1.1;
    font-size: 1cm;
  }
  .landing-page-print #hero .hero-text p {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.2;
    font-size: .6cm;
  }
  .landing-page-print #hero .hero-text a {
    color: #f27612 !important;
    font-weight: 300 !important;
  }
  .landing-page-print #hero .hero-figure {
    display: block;
    max-height: 465px;
    overflow: hidden;
    position: relative;
  }
  .landing-page-print #hero .hero-figure:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: 46.17414248%;
  }
  .landing-page-print #hero .hero-figure img {
    position: absolute;
    top: -1000%;
    left: -1000%;
    right: -1000%;
    bottom: -1000%;
    margin: auto;
  }
  .landing-page-print .product-grid .product-grid-item {
    width: 33.33333333% !important;
    page-break-inside: avoid;
    border-bottom: 0;
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  .landing-page-print .product-grid .product-grid-item:nth-child(3n-2) {
    clear: left;
  }
  .landing-page-print .product-grid .product-grid-item .more-info-btn {
    display: none;
  }
  .landing-page-print .product-grid .product-grid-item .more-info-btn + .hc-popover-content.bottom {
    display: none;
  }
  .landing-page-print .product-grid .product-grid-item .add-to-cart {
    display: none;
  }
  .landing-page-print .stock-status-green:before {
    border: 8px solid #3dbd54;
  }
  .landing-page-print #landing-page-content h2 {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: always;
  }
  .landing-page-print #landing-page-content h2 a {
    color: #f27612! important;
  }
}
